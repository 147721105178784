import * as React from "react";
import { ifProp } from "@wowvendor/sc-tools";
import styled from "styled-components";
import { Layout } from "antd";

import { excludeProps } from "lib/styled";

export interface CommonTemplateProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
}

export function CommonTemplate({ header, children }: CommonTemplateProps) {
  const withHeader = Boolean(header);

  return (
    <Layout>
      {header && <Layout.Header>{header}</Layout.Header>}
      <Content withHeader={withHeader}>{children}</Content>
    </Layout>
  );
}

interface ContentProps {
  withHeader?: boolean;
}

const Content = styled(excludeProps(["withHeader"], Layout.Content))<ContentProps>`
  display: grid;
  grid-template-rows: ${ifProp("withHeader", "auto 1fr", "1fr")};
  min-height: ${ifProp("withHeader", "calc(100vh - 64px)", "100vh")};

  @media (max-width: 575px) {
    padding: 0 15px;
  }
`;

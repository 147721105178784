import * as React from "react";
import { omit } from "ramda";

// eslint-disable-next-line @typescript-eslint/no-shadow
type Props<C> = C extends React.ComponentType<infer Props> ? Props : never;

/**
 * Stopping props to be passed on to child with styled-components
 *
 * @param {string[]} names - Props names to be excluded
 * @param {React.ComponentType} Component - Target component
 */
export function excludeProps<C extends React.ComponentType>(names: string[], Component: C) {
  return React.forwardRef<unknown, Props<C>>((props, ref) => {
    const componentProps = "ref" in props ? Object.assign({}, props, { ref }) : props;

    return React.createElement(Component, { ...omit(names, componentProps) });
  });
}

import * as React from "react";
import { ifProp } from "@wowvendor/sc-tools";
import styled from "styled-components";
import { Layout } from "antd";

import { excludeProps } from "lib/styled";

export interface SidebarTemplateProps {
  menu?: React.ReactNode;
  children?: React.ReactNode;
}

export function SidebarTemplate({ menu, children }: SidebarTemplateProps) {
  const [collapsed, onCollapse] = React.useState(true);

  return (
    <MainLayout>
      <Layout>
        <Sider collapsible width={180} collapsed={collapsed} onCollapse={onCollapse}>
          {menu}
        </Sider>
        <ContentLayout collapsed={collapsed}>
          <Layout.Content>{children}</Layout.Content>
        </ContentLayout>
      </Layout>
    </MainLayout>
  );
}

const MainLayout = styled(Layout)`
  min-height: 100vh;
`;

interface ContentLayoutProps {
  collapsed?: boolean;
}

const ContentLayout = styled(excludeProps(["collapsed"], Layout))<ContentLayoutProps>`
  padding: 16px;
  transition: margin-left 0.1s linear;
  margin-left: ${ifProp("collapsed", "80px", "180px")};
`;

const Sider = styled(Layout.Sider)`
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
`;
